import { NextSeo } from 'next-seo';
import { useContext } from 'use-context-selector';
import { HomepageContext } from '../homepageContext/HomepageProvider';

export const HomepageSEO = () => {
  const homepageContext = useContext(HomepageContext);
  if (homepageContext === undefined)
    throw new Error('HomepageSeo must be used within a HomepageContext');
  const { seo } = homepageContext;

  return <NextSeo title={seo?.title} description={seo?.description} />;
};
