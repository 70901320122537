import dynamic from 'next/dynamic';
import type { FormattedContentfulSection } from 'utils/types/contentfulSections';
import { ProductRecommendations } from '../productRecommendations/ProductRecommendationsWrapper';
import { WishlistButtonConnected } from '../wishlistButton/WishlistButtonConnected';

/**
 * Dynamic Imports for section components.
 */
const FindYourSystem = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/listing/findYourSystem/FindYourSystem'
  ).then((mod) => mod.FindYourSystem)
);

const ShareYourLooks = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/shop/shareYourLooks/ShareYourLooks'
  ).then((mod) => mod.ShareYourLooks)
);

const NailArtSection = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/listing/nailArtSection/NailArtSection'
  ).then((mod) => mod.NailArtSection)
);

const MainHero = dynamic(() =>
  import('@we-make-websites/ui-lib/src/modules/header/hero/Hero').then(
    (mod) => mod.HeroSlide
  )
);

const VideoHero = dynamic(() =>
  import('@we-make-websites/ui-lib/src/modules/header/hero/Hero').then(
    (mod) => mod.VideoHeroSlide
  )
);

const SliderHero = dynamic(() =>
  import('@we-make-websites/ui-lib/src/modules/header/hero/Hero').then(
    (mod) => mod.Hero
  )
);

const VideoPlayer = dynamic(() =>
  import('@we-make-websites/ui-lib/components').then((mod) => mod.VideoPlayer)
);

const CreatorOfMonth = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/shop/creatorOfMonth/CreatorOfMonth'
  ).then((mod) => mod.CreatorOfMonth)
);

const CollectionListingWrapper = dynamic(() =>
  import('../collectionListingWrapper/CollectionListingWrapper').then(
    (mod) => mod.CollectionListingWrapper
  )
);

const CollectionVideoSection = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/shop/collectionVideoSection/CollectionVideoSection'
  ).then((mod) => mod.CollectionVideoSection)
);

const NotFoundHero = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/header/notFoundHero/NotFoundHero'
  ).then((mod) => mod.NotFoundHero)
);

const CollectionListingSection = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/productFeatures/collectionListingSection/CollectionListingSection'
  ).then((mod) => mod.CollectionListingSection)
);

// const ProductRecommendations = dynamic(() =>
//   import('../productRecommendations/ProductRecommendationsWrapper').then(
//     (mod) => mod.ProductRecommendations
//   )
// );

const VideoLesson = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogEducation/videoLesson/VideoLesson'
  ).then((mod) => mod.VideoLesson)
);

const BlogHero = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogEducation/blogHero/BlogHero'
  ).then((mod) => mod.BlogHero)
);

const HowToApply = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogEducation/howToApply/HowToApply'
  ).then((mod) => mod.HowToApply)
);

const FeaturedTutorial = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogEducation/featuredTutorial/FeaturedTutorial'
  ).then((mod) => mod.FeaturedTutorial)
);

const ArticleListing = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogEducation/articleListing/ArticleListing'
  ).then((mod) => mod.ArticleListing)
);

const FeaturedArticles = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogEducation/featuredArticles/FeaturedArticles'
  ).then((mod) => mod.FeaturedArticles)
);

const Testimonies = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogEducation/testimonies/Testimonies'
  ).then((mod) => mod.Testimonies)
);

const EducationLink = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogEducation/educationLink/EducationLink'
  ).then((mod) => mod.EducationLink)
);

const Perks = dynamic(() =>
  import('@we-make-websites/ui-lib/src/modules/blogEducation/perks/Perks').then(
    (mod) => mod.Perks
  )
);

const MeetEducators = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogEducation/meetEducators/MeetEducators'
  ).then((mod) => mod.MeetEducators)
);

const VideoSectionTabbed = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogEducation/videoSectionTabbed/VideoSectionTabbed'
  ).then((mod) => mod.VideoSectionTabbed)
);

const ServiceEducationListing = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogEducation/serviceEducationListing/ServiceEducationListing'
  ).then((mod) => mod.ServiceEducationListing)
);

const InstagramEmbed = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/instagramEmbed/InstagramEmbed'
  ).then((mod) => mod.InstagramEmbed)
);

const BlogArticleStep = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogArticleStep/BlogArticleStep'
  ).then((mod) => mod.BlogArticleStep)
);

const BlogArticleTextImage = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogArticleTextImage/BlogArticleTextImage'
  ).then((mod) => mod.BlogArticleTextImage)
);

const BlogArticleSlider = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/blogArticleSlider/BlogArticleSlider'
  ).then((mod) => mod.BlogArticleSlider)
);

const EducationCourseListing = dynamic(() =>
  import('components/educationCourseListing/EducationCourseListing').then(
    (mod) => mod.EducationCourseListing
  )
);

const ColorSelector = dynamic(() =>
  import('components/colorSelector/ColorSelector').then(
    (mod) => mod.ColorSelector
  )
);

const GetLookSection = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/getLookSection/GetLookSection'
  ).then((mod) => mod.GetLookSection)
);

const WidgetRenderer = dynamic(() =>
  import('components/widgetRenderer/WidgetRenderer').then(
    (mod) => mod.WidgetRenderer
  )
);
const HtmlContent = dynamic(() =>
  import('@we-make-websites/ui-lib/src/modules/htmlContent/HtmlContent').then(
    (mod) => mod.HtmlContent
  )
);

const HtmlImageContent = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/htmlImageContent/HtmlImageContent'
  ).then((mod) => mod.HtmlImageContent)
);

const GocertifyScriptContent = dynamic(() =>
  import('components/gocertifyScript/GocertifyScriptContent').then(
    (mod) => mod.GocertifyScriptContent
  )
);

/**
 * Render out a section component based on the section typename.
 * @param section - The section object to process
 */
export const ContentSectionComponent = ({
  section,
  index,
  asIndex,
}: {
  section: FormattedContentfulSection;
  index?: number;
  asIndex?: boolean;
}) => {
  if (!section) return null;

  switch (section.__typename) {
    case 'MainHero': {
      return <MainHero {...section} eager={index === 0} single />;
    }

    case 'VideoHero': {
      return <VideoHero {...section} withMargins />;
    }

    case 'SliderHero': {
      return <SliderHero {...section} eager={index === 0} />;
    }

    case 'NailArtSection': {
      return <NailArtSection {...section} />;
    }

    case 'CollectionVideoSection': {
      return <CollectionVideoSection {...section} />;
    }

    case 'VideoSection': {
      return <VideoPlayer {...section} />;
    }

    case 'ShareYourLooks': {
      return <ShareYourLooks {...section} />;
    }

    case 'FindYourSystem': {
      return <FindYourSystem {...section} />;
    }

    case 'CreatorOfMonth': {
      return <CreatorOfMonth {...section} />;
    }

    case 'CollectionFeaturedListingSection': {
      return <CollectionListingWrapper {...section} />;
    }

    case 'NotFoundHero': {
      return <NotFoundHero {...section} />;
    }

    case 'CollectionListingSection': {
      return <CollectionListingSection {...section} />;
    }

    case 'ManualProductRecommendations': {
      return (
        <div className={'container'}>
          <ProductRecommendations {...section} asIndex={asIndex} />
        </div>
      );
    }

    case 'ModuleVideoLesson': {
      return <VideoLesson {...section} />;
    }

    case 'ModuleBlogHero': {
      return <BlogHero {...section} />;
    }

    case 'ModuleHowToApply': {
      return <HowToApply {...section} />;
    }

    case 'ModuleFeaturedTutorial': {
      return <FeaturedTutorial {...section} />;
    }

    case 'ModuleArticleListing': {
      return (
        <ArticleListing
          {...section}
          wishlistComponent={WishlistButtonConnected}
        />
      );
    }

    case 'ModuleFeaturedArticles': {
      return (
        <FeaturedArticles
          {...section}
          wishlistComponent={WishlistButtonConnected}
        />
      );
    }

    case 'ModuleTestimonies': {
      return <Testimonies {...section} />;
    }

    case 'ModuleEducationLink': {
      return <EducationLink {...section} />;
    }

    case 'ModulePerks': {
      return <Perks {...section} />;
    }

    case 'ModuleMeetEducators': {
      return <MeetEducators {...section} />;
    }

    case 'ModuleCollectionVideoLessonTabs': {
      return <VideoSectionTabbed {...section} />;
    }

    case 'ModuleServiceEducationListing': {
      return <ServiceEducationListing {...section} />;
    }

    case 'InstagramEmbed': {
      return <InstagramEmbed {...section} />;
    }

    case 'BlogArticleStep': {
      return <BlogArticleStep {...section} />;
    }

    case 'BlogArticleTextImage': {
      return <BlogArticleTextImage {...section} />;
    }

    case 'BlogArticleSlider': {
      return <BlogArticleSlider {...section} />;
    }

    case 'EduCourseListing': {
      return <EducationCourseListing {...section} />;
    }

    case 'ColorSelector': {
      return <ColorSelector {...section} />;
    }

    case 'GetLook': {
      return <GetLookSection {...section} />;
    }

    case 'WidgetPlaceholder': {
      return <WidgetRenderer {...section} />;
    }

    case 'HtmlContent': {
      return <HtmlContent {...section} />;
    }

    case 'HtmlImageContent': {
      return <HtmlImageContent {...section} />;
    }

    case 'ComponentsgocertifyScript': {
      return <GocertifyScriptContent {...section} />;
    }

    default:
      return null;
  }
};
