import { useContext } from 'use-context-selector';
import { ContentSectionComponent } from '../contentSectionComponent/ContentSectionComponent';
import { HomepageContext } from '../homepageContext/HomepageProvider';

export const HomepageSections = () => {
  const homepageContext = useContext(HomepageContext);
  if (homepageContext === undefined)
    throw new Error('HomepageSections must be used within a HomepageContext');
  const { sections, isLoading } = homepageContext;

  return (
    <div>
      {sections?.map((section, index) => {
        if (section?.__typename === 'GetLook') {
          return (
            <div className="container" key={index}>
              <ContentSectionComponent section={section} index={index} />
            </div>
          );
        }

        return (
          <ContentSectionComponent
            section={section}
            index={index}
            key={index}
          />
        );
      })}
      {isLoading && 'loading'}
    </div>
  );
};
